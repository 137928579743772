<template>
  <div>
    Projects
  </div>
</template>

<script>
export default {
  name: 'Projects',
  props: {
  }
}
</script>

<style scoped>
</style>
